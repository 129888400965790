import * as React from 'react';

export default function HeroContentDecoration({ className, style }) {
  return (
    <svg
      className={className}
      style={style}
      viewBox='0 0 1604 450'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1603 1.00003C1544.29 46.1975 1482.91 87.5969 1417.14 118.004C1395.82 127.863 1373.91 136.596 1351.12 140.502C1304.66 148.469 1257.55 136.027 1210.57 134.358C1150.92 132.236 1090.93 148.191 1038.13 179.489C998.395 203.04 953.695 237.201 908.752 245.774C867.924 253.561 824.002 211.188 787.846 194.656C750.812 177.724 709.392 174.117 670.282 182.827C649.793 187.391 629.864 195.332 611.488 206.548C593.917 217.275 578.609 237.239 557.778 221.549C550.892 216.362 546.132 208.37 540.215 201.837C519.649 179.134 483.685 177.36 461.442 197.953'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1600.41 2.41779C1543.04 48.2217 1482.29 90.0551 1416.88 120.351C1395.1 130.455 1372.73 138.923 1349.54 143.283C1321.57 148.501 1293.3 146.567 1264.94 143.816C1245.59 141.939 1226.19 139.679 1206.81 139.047C1168.52 137.803 1130.1 143.19 1093.47 154.537C1072.06 161.172 1051.26 169.85 1031.43 180.485C1017.51 187.919 1003.02 196.53 988.173 204.914C967.285 216.672 945.655 227.971 923.677 235.207C915.787 237.814 907.855 239.898 899.914 241.268C867.086 246.904 832.311 223.033 801.031 205.473C792.645 200.743 784.512 196.466 776.727 193.332C744.728 180.332 709.831 177.07 676.055 182.732C670.239 183.705 664.455 184.944 658.718 186.444C638.263 191.79 618.351 200.427 599.815 212.032C588.837 218.907 578.655 229.139 567.453 232.372C560.557 234.364 553.306 233.665 545.241 227.977C538.194 223.008 533.165 215.329 527.027 209.019C505.723 187.082 469.47 185.148 446.587 205.476'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1597.82 3.83099C1541.78 50.2445 1481.67 92.5088 1416.62 122.693C1394.38 133.046 1371.54 141.249 1347.96 146.056C1319.63 151.756 1290.94 150.364 1262.13 147.996C1242.47 146.381 1222.74 144.31 1203.04 143.729C1164.11 142.574 1125.05 147.492 1087.81 157.817C1066.04 163.853 1044.9 171.752 1024.73 181.474C1010.53 188.25 995.811 196.127 980.741 203.761C959.525 214.454 937.598 224.654 915.227 231.226C907.21 233.6 899.147 235.506 891.064 236.757C857.591 241.892 822.14 219.174 790.291 203.027C781.772 198.667 773.503 194.799 765.599 192.01C733.164 180.564 698.185 178.678 664.455 185.674C658.65 186.874 652.877 188.342 647.149 190.059C626.725 196.188 606.83 205.52 588.135 217.518C577.058 224.629 566.703 234.912 555.293 238.305C548.26 240.396 540.888 239.802 532.698 234.408C525.484 229.656 520.192 222.292 513.833 216.203C491.793 195.032 455.247 192.938 431.729 212.998'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1595.23 5.24538C1540.53 52.2653 1481.05 94.9668 1416.36 125.036C1393.66 135.635 1370.36 143.573 1346.38 148.833C1317.69 155.018 1288.58 154.166 1259.33 152.181C1239.35 150.831 1219.3 148.948 1199.27 148.412C1159.71 147.349 1120.01 151.795 1082.16 161.095C1060.04 166.534 1038.58 173.715 1018.04 182.461C1003.58 188.62 988.6 195.719 973.318 202.606C951.771 212.233 929.552 221.335 906.788 227.24C898.646 229.381 890.447 231.108 882.229 232.244C848.108 236.879 811.949 215.408 779.562 200.577C770.902 196.609 762.511 193.111 754.483 190.679C721.635 180.727 686.549 180.277 652.869 188.611C647.073 190.041 641.311 191.732 635.594 193.67C615.204 200.58 595.326 210.608 576.473 222.998C565.297 230.345 554.772 240.68 543.149 244.232C535.981 246.425 528.487 245.94 520.17 240.83C512.789 236.298 507.234 229.244 500.654 223.375C477.875 202.97 441.04 200.714 416.883 220.512'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1592.64 6.66202C1539.28 54.2883 1480.42 97.424 1416.1 127.384C1392.95 138.233 1369.2 145.957 1344.8 151.612C1315.76 158.344 1286.22 157.97 1256.52 156.371C1236.23 155.283 1215.86 153.591 1195.5 153.103C1155.3 152.133 1114.96 156.109 1076.51 164.385C1054.03 169.224 1032.23 175.646 1011.35 183.459C996.612 188.974 981.392 195.322 965.892 201.466C944.017 210.027 921.501 218.031 898.343 223.269C890.074 225.177 881.742 226.725 873.388 227.744C838.622 231.877 801.781 211.587 768.828 198.138C760.032 194.547 751.517 191.435 743.364 189.36C710.108 180.902 674.912 181.889 641.277 191.556C635.49 193.213 629.739 195.131 624.034 197.289C603.677 204.981 583.814 215.705 564.802 228.484C553.527 236.068 542.832 246.45 530.997 250.165C523.693 252.46 516.075 252.083 507.636 247.261C500.085 242.949 494.27 236.208 487.469 230.559C463.952 210.92 426.825 208.504 402.034 228.034'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1590.06 8.07582C1538.03 56.3118 1479.8 99.8815 1415.84 129.727C1392.23 140.821 1368.02 148.293 1343.23 154.386C1313.83 161.612 1283.86 161.768 1253.72 160.552C1233.12 159.729 1212.42 158.225 1191.73 157.782C1150.9 156.904 1109.91 160.409 1070.85 167.663C1048.02 171.905 1025.88 177.561 1004.66 184.445C989.648 189.312 974.196 194.959 958.467 200.311C936.277 207.866 913.444 214.648 889.901 219.282C881.502 220.936 873.043 222.327 864.547 223.227C829.133 226.859 791.615 207.748 758.094 195.686C749.165 192.472 740.52 189.747 732.242 188.029C698.582 181.056 663.272 183.488 629.683 194.493C623.907 196.379 618.167 198.52 612.468 200.898C592.145 209.375 572.296 220.795 553.125 233.964C541.752 241.784 530.884 252.217 518.84 256.092C511.4 258.489 503.657 258.221 495.096 253.686C487.376 249.594 481.3 243.166 474.279 237.737C450.025 218.864 412.607 216.289 387.177 235.554'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1587.47 9.49304C1536.77 58.3354 1479.18 102.339 1415.58 132.073C1391.51 143.416 1366.84 150.629 1341.65 157.166C1311.89 164.887 1281.5 165.57 1250.91 164.74C1230 164.178 1208.98 162.866 1187.97 162.47C1146.49 161.685 1104.87 164.721 1065.2 170.948C1042.01 174.589 1019.53 179.482 997.962 185.438C982.68 189.657 966.988 194.563 951.043 199.162C928.522 205.66 905.396 211.326 881.459 215.302C872.935 216.72 864.341 217.939 855.709 218.718C819.649 221.849 781.456 203.915 747.362 193.238C738.301 190.401 729.531 188.058 721.123 186.705C687.061 181.215 651.635 185.093 618.091 197.432C612.323 199.545 606.595 201.913 600.907 204.508C580.619 213.767 560.786 225.883 541.454 239.444C529.981 247.5 518.944 257.985 506.688 262.019C499.115 264.518 491.239 264.362 482.559 260.111C474.666 256.242 468.333 250.121 461.094 244.912C436.102 226.805 398.392 224.067 372.328 243.07'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1584.88 10.9068C1535.52 60.3556 1478.56 104.793 1415.32 134.415C1390.79 146.004 1365.66 152.959 1340.06 159.939C1309.95 168.152 1279.14 169.368 1248.1 168.921C1226.88 168.624 1205.53 167.5 1184.19 167.149C1142.08 166.457 1099.82 169.02 1059.54 174.225C1036 177.27 1013.18 181.397 991.264 186.424C975.704 189.995 959.771 194.161 943.612 198.01C920.76 203.448 897.341 208 873.012 211.319C864.363 212.5 855.633 213.544 846.863 214.204C810.155 216.834 771.29 200.075 736.626 190.787C727.431 188.323 718.533 186.367 709.998 185.374C675.537 181.362 639.989 186.692 606.493 200.368C600.734 202.708 595.018 205.306 589.341 208.118C569.087 218.159 549.268 230.97 529.778 244.921C518.206 253.213 506.996 263.749 494.53 267.943C486.821 270.541 478.815 270.503 470.019 266.529C461.951 262.884 455.363 257.072 447.903 252.083C422.175 234.74 384.174 231.845 357.47 250.58'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1582.29 12.3206C1534.26 62.379 1477.94 107.251 1415.06 136.761C1390.07 148.599 1364.49 155.292 1338.49 162.719C1308.02 171.42 1276.78 173.172 1245.29 173.111C1223.76 173.076 1202.09 172.144 1180.43 171.841C1137.67 171.241 1094.77 173.332 1053.89 177.516C1029.99 179.961 1006.83 183.318 984.57 187.423C968.734 190.343 952.563 193.771 936.189 196.867C913.005 201.249 889.296 204.687 864.57 207.345C855.794 208.29 846.933 209.158 838.024 209.701C800.671 211.83 761.136 196.242 725.894 188.345C716.566 186.255 707.544 184.684 698.882 184.052C664.022 181.512 628.355 188.301 594.907 203.314C589.16 205.884 583.454 208.705 577.783 211.737C557.563 222.56 537.758 236.067 518.109 250.411C506.439 258.939 495.059 269.523 482.381 273.879C474.536 276.579 466.397 276.659 457.488 272.963C449.244 269.545 442.401 264.036 434.721 259.271C408.254 242.693 369.962 239.639 342.624 258.109'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1579.7 13.7384C1533.01 64.4032 1477.32 109.709 1414.8 139.108C1389.35 151.192 1363.31 157.626 1336.9 165.497C1306.08 174.686 1274.42 176.974 1242.48 177.296C1220.64 177.526 1198.64 176.779 1176.65 176.524C1133.26 176.016 1089.72 177.638 1048.23 180.794C1023.98 182.642 1000.47 185.231 977.875 188.413C961.761 190.682 945.35 193.373 928.76 195.716C905.246 199.038 881.244 201.365 856.122 203.363C847.222 204.071 838.225 204.764 829.178 205.188C791.177 206.816 750.971 192.396 715.154 185.898C705.696 184.181 696.544 182.993 687.754 182.725C652.501 181.653 616.71 189.903 583.307 206.251C577.568 209.047 571.874 212.095 566.214 215.348C546.028 226.953 526.24 241.159 506.43 255.892C494.663 264.656 483.108 275.291 470.22 279.807C462.24 282.609 453.968 282.807 444.943 279.389C436.521 276.197 429.426 270.995 421.524 266.446C394.322 250.635 355.735 247.418 327.761 265.623'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1577.12 15.1522C1531.76 66.4234 1476.7 112.167 1414.54 141.45C1388.64 153.783 1362.13 159.956 1335.33 168.273C1304.14 177.95 1272.06 180.775 1239.67 181.48C1217.52 181.972 1195.2 181.417 1172.88 181.209C1128.86 180.794 1084.67 181.943 1042.57 184.079C1017.96 185.326 994.116 187.143 971.179 189.405C954.787 191.02 938.136 192.977 921.331 194.57C897.483 196.829 873.193 198.049 847.677 199.389C838.653 199.861 829.52 200.378 820.337 200.685C781.69 201.811 740.817 188.556 704.42 183.456C694.829 182.112 685.552 181.308 676.632 181.404C640.985 181.793 605.07 191.512 571.713 209.197C565.982 212.223 560.299 215.494 554.65 218.967C534.498 231.354 514.725 246.256 494.759 261.378C482.893 270.378 471.168 281.064 458.068 285.74C449.952 288.644 441.547 288.964 432.409 285.82C423.808 282.858 416.453 277.975 408.339 273.631C380.37 258.652 341.523 255.208 312.909 273.149'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1574.53 16.566C1530.5 68.4469 1476.07 114.621 1414.28 143.793C1387.92 156.371 1360.95 162.282 1333.75 171.047C1302.21 181.209 1269.7 184.573 1236.87 185.662C1214.4 186.418 1191.76 186.051 1169.12 185.888C1124.45 185.566 1079.62 186.242 1036.92 187.356C1011.95 188.007 987.76 189.048 964.486 190.392C947.817 191.352 930.925 192.572 913.908 193.414C889.729 194.611 865.147 194.726 839.236 195.403C830.083 195.642 820.821 195.98 811.496 196.169C772.201 196.794 730.66 184.701 693.686 181.002C683.965 180.028 674.561 179.607 665.51 180.073C629.47 181.918 593.427 193.111 560.118 212.13C554.399 215.382 548.727 218.884 543.087 222.573C522.969 235.742 503.21 251.34 483.085 266.855C471.12 276.092 459.223 286.825 445.913 291.664C437.664 294.67 429.12 295.114 419.872 292.238C411.09 289.509 403.486 284.926 395.151 280.799C366.447 266.587 327.305 262.983 298.057 280.659'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1571.94 17.9832C1529.25 70.4705 1475.45 117.082 1414.02 146.142C1387.2 158.97 1359.77 164.616 1332.17 173.83C1300.27 184.477 1267.34 188.378 1234.06 189.852C1211.28 190.87 1188.32 190.695 1165.35 190.58C1120.04 190.35 1074.57 190.558 1031.26 190.647C1005.94 190.698 981.404 190.966 957.787 191.391C940.841 191.697 923.708 192.179 906.477 192.272C881.963 192.406 857.093 191.416 830.788 191.429C821.511 191.432 812.113 191.595 802.652 191.668C762.712 191.793 720.503 180.858 682.952 178.566C673.1 177.963 663.569 177.925 654.391 178.758C617.958 182.055 581.79 194.726 548.526 215.083C542.815 218.565 537.155 222.289 531.524 226.199C511.439 240.153 491.697 256.444 471.412 272.351C459.347 281.824 447.28 292.609 433.758 297.607C425.373 300.712 416.691 301.284 407.332 298.679C398.372 296.186 390.516 291.9 381.961 287.993C352.52 274.55 313.087 270.781 283.199 288.191'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1569.35 19.397C1528 72.4907 1474.78 119.399 1413.76 148.485C1386.46 161.497 1358.59 166.939 1330.59 176.603C1298.33 187.733 1264.98 192.176 1231.25 194.036C1208.17 195.319 1184.88 195.332 1161.58 195.262C1115.64 195.125 1069.53 194.86 1025.61 193.925C999.934 193.379 975.053 192.865 951.097 192.377C933.874 192.026 916.5 191.77 899.056 191.119C874.209 190.19 849.053 188.097 822.349 187.446C812.948 187.216 803.413 187.2 793.814 187.155C753.226 186.779 710.351 176.999 672.22 176.115C662.239 175.882 652.58 176.227 643.272 177.427C606.445 182.173 570.153 196.325 536.937 218.016C531.235 221.724 525.586 225.676 519.966 229.806C499.915 244.542 480.187 261.528 459.743 277.825C447.58 287.534 435.34 298.369 421.609 303.527C413.088 306.735 404.27 307.434 394.8 305.098C385.66 302.841 377.554 298.851 368.781 295.165C338.605 282.491 298.877 278.559 268.356 295.704'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1566.76 20.8108C1526.75 74.5141 1474.16 121.853 1413.49 150.827C1385.74 164.086 1357.41 169.262 1329.01 179.377C1296.39 190.988 1262.62 195.974 1228.44 198.217C1205.04 199.762 1181.43 199.966 1157.81 199.944C1111.22 199.896 1064.48 199.165 1019.95 197.206C993.918 196.06 968.691 194.767 944.399 193.366C926.895 192.354 909.284 191.368 891.625 189.967C866.443 187.972 840.999 184.78 813.899 183.462C804.37 182.999 794.706 182.808 784.968 182.642C743.734 181.764 700.192 173.14 661.48 173.664C651.366 173.801 641.583 174.529 632.145 176.096C594.922 182.285 558.508 197.924 525.337 220.952C519.646 224.887 514.009 229.065 508.397 233.415C488.38 248.933 468.667 266.618 448.064 283.305C435.802 293.247 423.392 304.133 409.449 309.454C400.792 312.764 391.835 313.594 382.258 311.522C372.933 309.505 364.585 305.806 355.588 302.34C324.676 290.432 284.657 286.34 253.496 303.217'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1564.17 22.2275C1525.49 76.5372 1473.54 124.314 1413.24 153.173C1385.02 166.677 1356.24 171.589 1327.43 182.156C1294.46 194.25 1260.26 199.778 1225.64 202.404C1201.93 204.214 1177.99 204.607 1154.04 204.629C1106.82 204.674 1059.44 203.47 1014.3 200.489C987.913 198.747 962.341 196.672 937.709 194.358C919.928 192.689 902.076 190.965 884.202 188.817C858.689 185.753 832.953 181.467 805.46 179.482C795.806 178.786 786.006 178.416 776.132 178.132C734.251 176.753 690.044 169.278 650.752 171.215C640.507 171.72 630.597 172.83 621.028 174.771C583.412 182.399 546.871 199.529 513.749 223.894C508.066 228.059 502.44 232.464 496.839 237.031C476.857 253.331 457.16 271.712 436.396 288.791C424.035 298.969 411.455 309.907 397.3 315.387C388.507 318.799 379.411 319.763 369.724 317.953C360.218 316.175 351.62 312.77 342.403 309.524C310.755 298.382 270.442 294.127 238.647 310.743'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1561.59 23.6418C1524.24 78.558 1472.96 126.906 1412.97 155.516C1384.32 169.333 1355.05 173.913 1325.85 184.93C1292.51 197.506 1257.9 203.576 1222.83 206.586C1198.81 208.657 1174.55 209.238 1150.27 209.312C1102.41 209.449 1054.38 207.773 1008.64 203.768C981.899 201.425 955.985 198.568 931.01 195.345C912.952 193.012 894.86 190.557 876.773 187.666C850.927 183.536 824.902 178.151 797.012 175.499C787.235 174.57 777.298 174.021 767.286 173.619C724.759 171.739 679.884 165.413 640.012 168.768C629.637 169.642 619.596 171.133 609.904 173.444C571.891 182.508 535.228 201.131 502.151 226.831C496.48 231.223 490.862 235.854 485.27 240.641C465.322 257.723 445.639 276.8 424.717 294.268C412.256 304.682 399.504 315.671 385.142 321.311C376.216 324.822 366.979 325.923 357.184 324.372C347.497 322.837 338.656 319.699 329.213 316.696C296.846 306.246 256.224 301.906 223.789 318.254'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1559 25.0562C1522.98 80.582 1472.34 129.364 1412.71 157.862C1383.61 171.925 1353.88 176.24 1324.27 187.711C1290.58 200.765 1255.54 207.378 1220.02 210.777C1195.69 213.113 1171.1 213.883 1146.5 214.001C1098.01 214.231 1049.34 212.086 1002.99 207.056C975.888 204.116 949.629 200.474 924.314 196.341C905.979 193.347 887.649 190.156 869.344 186.52C843.167 181.321 816.85 174.842 788.567 171.523C778.665 170.361 768.596 169.633 758.445 169.113C715.27 166.732 669.727 161.549 629.281 166.323C618.773 167.565 608.607 169.435 598.785 172.119C560.373 182.617 523.591 202.737 490.56 229.774C484.897 234.396 479.293 239.25 473.71 244.255C453.795 262.119 434.129 281.891 413.046 299.752C400.486 310.402 387.564 321.439 372.99 327.242C363.928 330.855 354.552 332.093 344.65 330.8C334.782 329.511 325.692 326.658 316.028 323.875C282.925 314.191 242.009 309.691 208.937 325.774'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1556.41 26.4728C1521.73 82.605 1471.72 131.821 1412.46 160.208C1382.89 174.519 1352.7 178.563 1322.69 190.487C1288.64 204.02 1253.18 211.179 1217.21 214.961C1192.58 217.559 1167.67 218.52 1142.74 218.686C1093.6 219.008 1044.29 216.391 997.337 210.339C969.883 206.8 943.284 202.373 917.627 197.333C899.014 193.676 880.443 189.753 861.927 185.371C835.415 179.099 808.81 171.532 780.131 167.545C770.102 166.151 759.899 165.244 749.61 164.606C705.787 161.724 659.576 157.683 618.552 163.878C607.914 165.483 597.619 167.737 587.668 170.794C548.858 182.719 511.957 204.342 478.974 232.713C473.319 237.561 467.727 242.642 462.155 247.867C442.274 266.513 422.623 286.985 401.383 305.231C388.725 316.118 375.63 327.206 360.846 333.168C351.649 336.883 342.137 338.262 332.122 337.225C322.073 336.184 312.734 333.615 302.848 331.052C269.007 322.131 227.802 317.472 194.094 333.293'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1553.82 27.8872C1520.48 84.6258 1471.1 134.276 1412.19 162.551C1382.17 177.108 1351.51 180.884 1321.11 193.264C1286.7 207.276 1250.82 214.981 1214.4 219.146C1189.45 222.009 1164.22 223.158 1138.96 223.368C1089.19 223.783 1039.24 220.697 991.674 213.618C963.866 209.481 936.922 204.266 910.925 198.32C892.038 193.998 873.224 189.342 854.492 184.219C827.65 176.878 800.753 168.219 771.681 163.563C761.527 161.935 751.189 160.85 740.763 160.093C696.295 156.71 649.413 153.812 607.812 161.43C597.041 163.403 586.621 166.036 576.54 169.467C537.328 182.818 500.309 205.945 467.373 235.653C461.73 240.731 456.147 246.039 450.586 251.481C430.739 270.912 411.101 292.076 389.704 310.715C376.946 321.838 363.682 332.977 348.686 339.102C339.352 342.919 329.702 344.438 319.579 343.657C309.346 342.865 299.761 340.577 289.655 338.237C255.078 330.082 213.578 325.263 179.233 340.816'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1551.23 29.3004C1519.23 86.6486 1470.48 136.733 1411.94 164.893C1381.45 179.699 1350.34 183.204 1319.53 196.037C1284.76 210.528 1248.46 218.778 1211.6 223.326C1186.34 226.451 1160.78 227.791 1135.2 228.05C1084.79 228.557 1034.19 224.999 986.025 216.898C957.861 212.162 930.577 206.161 904.238 199.309C885.073 194.323 866.022 188.933 847.072 183.066C819.898 174.653 792.711 164.906 763.242 159.579C752.96 157.721 742.492 156.454 731.925 155.579C686.809 151.695 639.257 149.937 597.081 158.979C586.18 161.315 575.63 164.331 565.425 168.139C525.807 182.916 488.675 207.546 455.785 238.589C450.15 243.893 444.581 249.428 439.025 255.09C419.21 275.303 399.592 297.163 378.033 316.195C365.179 327.554 351.739 338.741 336.534 345.028C327.065 348.948 317.276 350.611 307.045 350.078C296.631 349.538 286.797 347.531 276.47 345.408C241.154 338.019 199.366 333.04 164.385 348.329'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1548.64 30.7177C1517.97 88.6723 1469.85 139.19 1411.67 167.242C1380.73 182.294 1349.15 185.527 1317.95 198.82C1282.82 213.786 1246.1 222.586 1208.79 227.52C1183.22 230.91 1157.33 232.439 1131.43 232.742C1080.38 233.342 1029.14 229.311 980.365 220.189C951.847 214.856 924.221 208.064 897.537 200.308C878.097 194.659 858.805 188.534 839.64 181.924C812.139 172.438 784.653 161.602 754.791 155.605C744.385 153.515 733.781 152.069 723.078 151.076C677.317 146.691 629.091 146.068 586.341 156.537C575.307 159.24 564.626 162.636 554.297 166.817C514.275 183.015 477.029 209.155 444.184 241.535C438.558 247.069 433 252.827 427.456 258.709C407.674 279.704 388.071 302.26 366.353 321.681C353.401 333.277 339.791 344.515 324.373 350.962C314.771 354.98 304.843 356.79 294.5 356.509C283.907 356.222 273.821 354.492 263.276 352.593C227.222 345.97 185.143 340.831 149.524 355.851'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1546.05 32.1315C1516.72 90.6925 1469.23 141.648 1411.41 169.585C1380.02 184.886 1347.97 187.847 1316.37 201.594C1280.87 217.035 1243.74 226.384 1205.98 231.701C1180.1 235.352 1153.89 237.07 1127.66 237.424C1075.97 238.116 1024.1 233.616 974.711 223.467C945.836 217.533 917.87 209.953 890.844 201.294C871.13 194.978 851.594 188.122 832.214 180.771C804.381 170.213 776.605 158.292 746.349 151.622C735.816 149.301 725.079 147.677 714.237 146.566C667.828 141.68 618.931 142.19 575.61 154.089C564.442 157.156 553.635 160.932 543.178 165.49C502.748 183.108 465.392 210.757 432.595 244.474C426.981 250.235 421.431 256.22 415.898 262.322C396.15 284.099 376.561 307.351 354.685 327.164C341.633 338.996 327.851 350.285 312.224 356.892C302.486 361.012 292.422 362.969 281.968 362.937C271.195 362.905 260.859 361.453 250.094 359.771C213.304 353.914 170.93 348.613 134.678 363.371'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1543.47 33.5458C1515.47 92.7165 1468.61 144.103 1411.15 171.928C1379.3 187.474 1346.77 190.095 1314.79 204.368C1278.91 220.208 1241.38 230.179 1203.17 235.883C1176.98 239.799 1150.45 241.704 1123.89 242.103C1071.56 242.888 1019.05 237.916 969.056 226.745C939.828 220.215 911.522 211.843 884.151 202.281C864.162 195.297 844.386 187.711 824.791 179.616C796.63 167.986 768.545 155.047 737.907 147.636C727.244 145.105 716.38 143.279 705.399 142.05C658.345 136.663 608.772 138.31 564.878 151.638C553.578 155.069 542.643 159.228 532.059 164.159C491.222 183.198 453.752 212.357 421.004 247.408C415.397 253.395 409.862 259.61 404.335 265.929C384.621 288.488 365.049 312.438 343.012 332.642C329.861 344.71 315.909 356.046 300.069 362.816C290.195 367.039 279.996 369.142 269.431 369.359C258.477 369.582 247.892 368.408 236.906 366.946C199.378 361.855 156.715 356.394 119.823 370.888'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1540.88 34.963C1514.21 94.7401 1467.99 146.56 1410.89 174.274C1378.58 190.069 1345.59 192.425 1313.21 207.148C1276.97 223.47 1239.02 233.984 1200.36 240.07C1173.86 244.248 1147.01 246.345 1120.12 246.792C1067.16 247.67 1014 242.228 963.402 230.032C933.817 222.902 905.175 213.742 877.458 203.277C857.195 195.626 837.192 187.267 817.365 178.471C788.895 165.71 760.494 151.734 729.465 143.659C718.677 140.895 707.677 138.891 696.558 137.544C648.856 131.655 598.609 134.435 554.144 149.193C542.711 152.988 531.645 157.527 520.939 162.838C479.692 183.293 442.115 213.965 409.412 250.354C403.814 256.571 398.29 263.009 392.774 269.549C373.094 292.889 353.536 317.536 331.34 338.128C318.09 350.432 303.969 361.82 287.917 368.749C277.907 373.074 267.572 375.324 256.894 375.787C245.761 376.269 234.925 375.366 223.718 374.127C185.454 369.803 142.497 364.179 104.969 378.408'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1538.29 36.3768C1512.96 96.7603 1467.37 149.018 1410.63 176.619C1377.86 192.661 1344.41 194.751 1311.64 209.925C1275.03 226.732 1236.66 237.785 1197.56 244.258C1170.75 248.7 1143.57 250.986 1116.35 251.477C1062.75 252.448 1008.96 246.533 957.748 233.317C927.809 225.589 898.827 215.635 870.762 204.269C850.228 195.951 829.982 186.865 809.937 177.325C781.136 163.495 752.442 148.424 721.018 139.682C710.103 136.685 698.969 134.502 687.715 133.037C639.367 126.647 588.441 130.557 543.407 146.748C531.841 150.907 520.645 155.826 509.815 161.513C468.154 183.386 430.473 215.571 397.815 253.293C392.228 259.737 386.713 266.402 381.208 273.162C361.562 297.284 342.018 322.626 319.667 343.612C306.318 356.152 292.023 367.591 275.762 374.68C265.617 379.106 255.146 381.507 244.358 382.215C233.046 382.959 221.958 382.327 210.531 381.305C171.528 377.747 128.282 371.963 90.1169 385.927'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1535.7 37.7901C1511.71 98.7832 1466.75 151.472 1410.37 178.962C1377.14 195.252 1343.23 197.074 1310.05 212.701C1273.09 229.99 1234.3 241.586 1194.75 248.441C1167.63 253.149 1140.12 255.623 1112.58 256.162C1058.34 257.225 1003.91 250.838 952.093 236.597C921.798 228.27 892.479 217.527 864.069 205.258C843.26 196.273 822.774 186.462 802.514 176.172C773.384 161.276 744.394 145.107 712.579 135.701C701.539 132.471 690.27 130.11 678.876 128.527C629.881 121.636 578.278 126.679 532.678 144.3C520.976 148.822 509.654 154.121 498.699 160.185C456.625 183.471 418.839 217.172 386.226 256.232C380.648 262.906 375.146 269.794 369.65 276.774C350.039 301.682 330.512 327.716 307.999 349.094C294.551 361.871 280.087 373.357 263.613 380.609C253.334 385.135 242.728 387.691 231.826 388.642C220.337 389.645 208.997 389.284 197.349 388.486C157.607 385.693 114.07 379.75 75.2678 393.446'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1533.11 39.2078C1510.45 100.807 1466.12 153.933 1410.11 181.308C1376.42 197.844 1342.05 199.402 1308.48 215.478C1271.15 233.25 1231.94 245.387 1191.94 252.626C1164.5 257.596 1136.68 260.261 1108.81 260.848C1053.93 262 998.856 255.148 946.433 239.882C915.784 230.958 886.146 219.369 857.37 206.254C836.304 196.564 815.56 186.064 795.082 175.027C765.622 159.065 736.34 141.795 704.128 131.725C692.961 128.262 681.562 125.722 670.03 124.02C620.389 116.629 568.076 122.709 521.939 141.859C510.095 146.72 498.65 152.423 487.571 158.864C445.081 183.568 407.19 218.782 374.628 259.176C369.059 266.076 363.569 273.193 358.081 280.391C338.503 306.081 318.991 332.811 296.319 354.578C282.772 367.588 268.135 379.129 251.452 386.54C241.038 391.168 230.298 393.874 219.281 395.071C207.616 396.338 196.021 396.246 184.152 395.668C143.675 393.641 99.8464 387.536 60.4075 400.969'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1530.53 40.6222C1509.2 102.828 1465.5 156.388 1409.85 183.651C1375.71 200.436 1340.87 201.729 1306.9 218.256C1269.22 236.509 1229.58 249.186 1189.13 256.811C1161.39 262.045 1133.24 264.899 1105.04 265.531C1049.52 266.776 993.808 259.451 940.776 243.163C909.774 233.639 879.795 221.272 850.675 207.244C829.334 196.894 808.349 185.659 787.656 173.878C757.867 156.851 728.288 138.483 695.684 127.746C684.392 124.047 672.857 121.33 661.189 119.511C610.9 111.618 557.914 118.847 511.204 139.411C499.228 144.642 487.642 150.697 476.452 157.537C433.498 183.559 395.553 220.384 363.037 262.116C357.479 269.246 351.997 276.587 346.521 284.001C326.977 310.473 307.481 337.902 284.648 360.059C271.002 373.304 256.196 384.897 239.3 392.467C228.75 397.197 217.877 400.057 206.747 401.493C194.906 403.022 183.057 403.198 170.97 402.84C129.754 401.58 85.6341 395.314 45.5584 408.483'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1527.94 42.036C1507.95 104.852 1464.88 158.842 1409.59 185.994C1374.99 203.025 1339.69 204.052 1305.31 221.029C1267.27 239.767 1227.22 252.984 1186.32 260.992C1158.27 266.488 1129.79 269.53 1101.27 270.213C1045.12 271.55 988.759 263.753 935.122 246.441C903.763 236.32 873.445 223.171 843.982 208.23C822.366 197.222 801.138 185.253 780.23 172.723C750.11 154.629 720.24 135.163 687.242 123.759C675.823 119.827 664.155 116.932 652.348 114.995C601.414 106.601 547.751 114.982 500.47 136.957C488.361 142.555 476.644 148.993 465.33 156.206C421.972 183.673 383.914 221.98 351.442 265.049C345.893 272.405 340.425 279.973 334.957 287.607C315.447 314.861 295.966 342.986 272.975 365.536C259.229 379.017 244.25 390.657 227.146 398.391C216.459 403.223 205.456 406.236 194.21 407.915C182.194 409.709 170.09 410.152 157.782 410.015C115.83 409.517 71.419 403.095 30.7065 415.996'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1525.35 43.4527C1506.69 106.875 1464.26 161.302 1409.33 188.342C1374.27 205.622 1338.51 206.382 1303.74 223.808C1265.34 243.029 1224.86 256.788 1183.51 265.182C1155.15 270.943 1126.35 274.173 1097.5 274.901C1040.71 276.331 983.713 268.064 929.465 249.728C897.752 239.007 867.092 225.079 837.283 209.226C815.393 197.557 793.925 184.854 772.799 171.577C742.351 152.417 712.183 131.85 678.792 119.782C667.248 115.617 655.447 112.546 643.501 110.488C591.919 101.592 537.583 111.126 489.73 134.515C477.488 140.483 465.644 147.304 454.203 154.884C410.437 183.797 372.268 223.588 339.842 267.994C334.301 275.578 328.845 283.372 323.389 291.226C303.912 319.262 284.445 348.083 261.295 371.022C247.451 384.74 232.302 396.431 214.985 404.324C204.163 409.258 193.033 412.45 181.665 414.346C169.476 416.43 157.114 417.113 144.586 417.199C101.895 417.467 57.1927 410.886 15.8435 423.522'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1522.76 44.867C1505.44 108.895 1463.64 163.757 1409.07 190.686C1373.55 208.211 1337.33 208.706 1302.16 226.586C1263.4 246.288 1222.5 260.59 1180.71 269.367C1095.58 287.247 1006.53 282.204 923.81 253.01C868.167 233.371 815.752 203.529 765.375 170.425C734.596 150.199 704.137 128.534 670.352 115.799C608.242 92.388 538.63 101.529 479.002 132.064C419.374 162.599 369.113 213.209 328.256 270.931C291.177 323.31 257.874 384.96 202.839 410.251C139.897 439.174 66.758 411.659 1 431.035'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1391.37 449.6C1392.06 368.675 1366.69 287.779 1321.03 225.346C1308.04 207.585 1292.81 190.79 1273.6 182.999C1218.09 160.491 1158.82 209.694 1112.15 237.631C1055.83 271.342 1000.15 306.67 941.506 335.118C908.585 351.089 869.919 364.644 838.073 346.107C805.825 327.333 793.554 283.62 780.222 245.553C764.688 201.195 743.296 158.465 711.894 126.522C680.492 94.5797 638.042 74.4147 596.006 79.6139C563.087 83.6865 533.092 102.524 505.7 123.516C433.73 178.674 372.588 251.697 327.877 335.89'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1393.17 446.102C1393.78 374.579 1371.47 304.579 1331.92 244.544C1328.24 238.968 1324.46 233.484 1320.52 228.151C1307.55 210.632 1292.44 194.051 1273.51 186.2C1218.73 163.465 1160.37 210.096 1113.76 237.03C1072.45 260.911 1031.42 286.125 988.935 308.949C974.196 316.899 959.33 324.556 944.274 331.779C911.616 347.444 873.408 360.817 841.8 343.144C809.795 325.249 797.125 283.227 783.425 246.65C767.459 204.032 745.82 162.961 714.478 132.251C703.566 121.558 691.354 112.239 678.311 104.844C653.905 90.998 626.584 83.8806 599.436 87.1169C566.825 91.0044 537.048 109.079 509.832 129.113C454.732 169.644 406.042 219.955 365.901 277.91C353.921 295.202 342.706 313.171 332.317 331.769'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1394.96 442.607C1395.56 373.469 1372.06 307.347 1331.38 247.097C1327.69 241.63 1323.93 236.217 1320 230.963C1307.05 213.69 1292.08 197.32 1273.41 189.404C1219.37 166.44 1161.92 210.501 1115.37 236.437C1074.49 259.219 1033.82 283.766 991.36 306.108C976.729 313.87 961.973 321.365 947.039 328.447C914.644 343.808 876.892 356.997 845.524 340.189C813.763 323.168 800.694 282.838 786.629 247.755C770.232 206.876 748.342 167.465 717.062 137.989C706.169 127.728 694.017 118.782 681.061 111.687C656.828 98.3966 629.759 91.5473 602.868 94.6304C570.567 98.336 541.01 115.644 513.967 134.718C459.195 173.296 410.824 220.842 370.568 276.132C358.554 292.63 347.265 309.811 336.76 327.655'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1396.76 439.109C1397.34 372.355 1372.67 310.111 1330.85 249.648C1327.14 244.282 1323.4 238.943 1319.49 233.772C1306.55 216.741 1291.71 200.588 1273.32 192.609C1220.01 169.418 1163.48 210.91 1116.99 235.84C1076.55 257.528 1036.19 281.293 993.794 303.268C979.253 310.807 964.625 318.173 949.81 325.115C917.678 340.17 880.381 353.176 849.257 337.234C817.739 321.087 804.271 282.452 789.838 248.859C773.008 209.72 750.872 171.968 719.654 143.725C708.778 133.895 696.689 125.322 683.821 118.524C659.757 105.789 632.946 99.2076 606.307 102.141C574.314 105.664 544.978 122.207 518.107 140.32C463.66 176.947 415.613 221.727 375.24 274.355C363.193 290.058 351.833 306.45 341.212 323.545'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1398.56 435.614C1399.11 371.244 1373.28 312.872 1330.31 252.201C1326.59 246.941 1322.87 241.671 1318.97 236.584C1306.05 219.799 1291.34 203.856 1273.22 195.813C1220.65 172.396 1165.04 211.325 1118.6 235.247C1078.6 255.846 1038.57 278.899 996.216 300.427C981.778 307.768 967.268 314.981 952.572 321.783C920.703 336.535 883.865 349.359 852.978 334.278C821.704 319.009 807.837 282.065 793.036 249.963C775.773 212.563 753.391 176.469 722.232 149.46C711.376 140.058 699.349 131.862 686.566 125.36C662.675 113.184 636.118 106.871 609.731 109.647C578.044 112.986 548.929 128.766 522.233 145.918C468.115 180.592 420.387 222.607 379.895 272.57C367.811 287.478 356.381 303.083 345.644 319.424'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1400.36 432.116C1400.89 370.131 1373.91 315.626 1329.78 254.751C1326.04 249.593 1322.34 244.401 1318.45 239.393C1305.56 222.85 1290.97 207.122 1273.12 199.018C1221.29 175.374 1166.62 211.743 1120.21 234.653C1080.66 254.174 1040.97 276.509 998.644 297.59C984.308 304.733 969.914 311.793 955.34 318.454C923.734 332.9 887.352 345.542 856.706 331.326C825.675 316.932 811.411 281.683 796.24 251.071C778.546 215.407 755.915 180.975 724.816 155.203C713.98 146.231 702.012 138.408 689.316 132.203C665.598 120.582 639.293 114.537 613.164 117.164C581.785 120.321 552.891 135.338 526.368 151.529C472.578 184.253 425.17 223.501 384.562 270.802C372.444 284.916 360.94 299.732 350.087 315.32'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1402.16 428.618C1402.67 369.017 1374.56 318.371 1329.25 257.298C1325.49 252.243 1321.81 247.123 1317.94 242.198C1305.06 225.902 1290.61 210.384 1273.03 202.216C1221.93 178.346 1168.2 212.158 1121.82 234.05C1082.74 252.498 1043.36 274.109 1001.07 294.74C986.837 301.685 972.56 308.592 958.107 315.109C926.765 329.252 890.841 341.709 860.433 328.358C829.645 314.838 814.983 281.284 799.443 252.163C781.317 218.238 758.439 185.466 727.4 160.925C716.583 152.384 704.678 144.935 692.067 139.03C668.519 127.965 642.471 122.188 616.594 124.661C585.523 127.636 556.847 141.887 530.499 157.118C477.038 187.889 429.949 224.373 389.226 269.009C377.071 282.328 365.496 296.355 354.53 311.19'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1403.95 425.124C1404.44 367.906 1375.22 321.116 1328.71 259.852C1324.94 254.895 1321.28 249.852 1317.42 245.011C1304.56 228.956 1290.24 213.652 1272.93 205.424C1222.57 181.324 1169.79 212.593 1123.43 233.46C1084.82 250.842 1045.76 271.725 1003.5 291.906C989.365 298.656 975.203 305.404 960.872 311.784C929.793 325.62 894.325 337.895 864.157 325.409C833.613 312.767 818.551 280.901 802.647 253.274C784.091 221.089 760.964 189.976 729.987 166.671C719.19 158.557 707.344 151.485 694.821 145.877C671.445 135.367 645.652 129.861 620.027 132.181C589.265 134.971 560.809 148.459 534.631 162.729C481.498 191.55 434.729 225.267 393.887 267.241C381.698 279.765 370.05 293.004 358.967 307.086'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1405.75 421.625C1406.22 366.791 1375.89 323.847 1328.18 262.401C1324.4 257.543 1320.75 252.58 1316.9 247.818C1304.06 232.01 1289.87 216.919 1272.83 208.627C1223.21 184.3 1171.39 213.026 1125.04 232.862C1086.9 249.187 1048.16 269.333 1005.93 289.061C991.9 295.614 977.852 302.208 963.64 308.447C932.824 321.98 897.814 334.07 867.884 322.453C837.583 310.685 822.123 280.514 805.85 254.377C786.861 223.932 763.485 194.479 732.57 172.405C721.794 164.723 710.009 158.023 697.572 152.712C674.368 142.761 648.83 137.52 623.457 139.69C593.003 142.298 564.768 155.02 538.763 168.332C485.958 195.2 439.509 226.153 398.551 265.462C386.325 277.191 374.606 289.639 363.408 302.974'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1407.55 418.127C1408 365.678 1376.58 326.57 1327.64 264.952C1323.86 260.193 1320.22 255.306 1316.39 250.627C1303.57 235.061 1289.5 220.185 1272.74 211.829C1223.85 187.275 1172.99 213.466 1126.66 232.265C1089 247.544 1050.56 266.95 1008.36 286.221C994.43 292.582 980.498 299.016 966.408 305.116C935.855 318.342 901.301 330.25 871.611 319.497C841.554 308.604 825.694 280.128 809.054 255.482C789.632 226.776 766.01 198.983 735.154 178.144C724.397 170.893 712.672 164.567 700.322 159.553C677.289 150.156 652.006 145.187 626.889 147.201C596.744 149.623 568.727 161.582 542.898 173.934C490.421 198.852 444.292 227.037 403.217 263.684C390.958 274.619 379.165 286.278 367.854 298.86'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1409.35 414.632C1409.78 364.567 1377.28 329.29 1327.11 267.502C1323.32 262.839 1319.69 258.032 1315.87 253.433C1303.07 238.113 1289.14 223.447 1272.64 215.031C1224.48 190.25 1174.6 213.914 1128.26 231.666C1091.09 245.907 1052.95 264.562 1010.78 283.374C996.958 289.541 983.139 295.819 969.17 301.777C938.88 314.701 904.784 326.424 875.333 316.536C845.518 306.517 829.26 279.736 812.252 256.58C792.399 229.613 768.528 203.48 737.732 183.873C726.995 177.05 715.33 171.097 703.067 166.383C680.207 157.542 655.178 152.841 630.314 154.705C600.474 156.945 572.681 168.138 547.024 179.529C494.875 202.494 449.066 227.912 407.873 261.897C395.579 272.037 383.713 282.908 372.285 294.737'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1411.15 411.134C1411.56 363.453 1377.99 331.993 1326.57 270.052C1322.78 265.485 1319.16 260.761 1315.36 256.241C1302.57 241.164 1288.77 226.712 1272.55 218.232C1225.13 193.225 1176.22 214.373 1129.88 231.069C1093.2 244.282 1055.35 262.178 1013.21 280.533C999.493 286.508 985.788 292.627 971.94 298.445C941.914 311.062 908.274 322.606 879.065 313.58C849.495 304.439 832.84 279.346 815.461 257.687C795.176 232.457 771.058 207.986 740.324 189.612C729.607 183.219 718.004 177.64 705.827 173.226C683.138 164.94 658.365 160.507 633.752 162.218C604.221 164.276 576.646 174.704 551.164 185.137C499.344 206.151 453.854 228.803 412.545 260.123C400.215 269.468 388.278 279.55 376.737 290.626'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1412.95 407.639C1413.33 362.343 1378.72 334.687 1326.04 272.605C1322.24 268.134 1318.63 263.48 1314.84 259.054C1302.1 244.193 1288.4 229.984 1272.45 221.44C1225.77 196.203 1177.85 214.846 1131.49 230.478C1095.31 242.677 1057.76 259.807 1015.64 277.696C1002.03 283.48 988.436 289.435 974.705 295.113C944.942 307.427 911.76 318.786 882.79 310.625C853.462 302.358 836.409 278.96 818.665 258.792C797.946 235.301 773.583 212.49 742.908 195.351C732.211 189.389 720.667 184.183 708.577 180.066C686.059 172.339 661.54 168.174 637.185 169.731C607.962 171.605 580.608 181.269 555.299 190.742C503.807 209.803 458.637 229.69 417.212 258.348C404.847 266.899 392.837 276.193 381.18 286.518'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1414.75 404.141C1415.11 361.229 1379.46 337.365 1325.5 275.156C1321.7 270.777 1318.1 266.209 1314.32 261.862C1301.6 247.244 1288.03 233.249 1272.36 224.641C1226.4 199.178 1179.48 215.327 1133.1 229.878C1097.43 241.072 1060.16 257.426 1018.07 274.852C1004.56 280.444 991.082 286.24 977.473 291.778C947.973 303.785 915.247 314.962 886.517 307.666C857.433 300.274 839.98 278.571 821.868 259.893C800.719 238.142 776.107 216.99 745.492 201.083C734.811 195.549 723.333 190.72 711.325 186.899C688.98 179.727 664.715 175.83 640.612 177.235C611.698 178.926 584.564 187.825 559.428 196.337C508.264 213.448 463.414 230.568 421.87 256.561C409.469 264.317 397.387 272.822 385.615 282.394'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1416.54 400.643C1416.89 360.115 1380.22 340.03 1324.97 277.706C1321.17 273.419 1317.57 268.935 1313.8 264.671C1301.1 250.295 1287.66 236.514 1272.26 227.845C1227.04 202.155 1181.12 215.822 1134.71 229.285C1099.55 239.485 1062.56 255.054 1020.49 272.012C1007.09 277.412 993.726 283.049 980.238 288.446C951.001 300.15 918.734 311.142 890.241 304.71C861.401 298.196 843.549 278.184 825.069 260.997C803.487 240.985 778.626 221.494 748.073 206.821C737.412 201.718 725.993 197.262 714.073 193.739C691.9 187.122 667.891 183.493 644.039 184.748C615.434 186.257 588.517 194.39 563.554 201.945C512.719 217.102 468.189 231.458 426.528 254.789C414.093 261.75 401.938 269.468 390.052 278.287'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1418.34 397.148C1418.67 359.004 1380.99 342.682 1324.43 280.256C1320.63 276.062 1317.05 271.661 1313.29 267.479C1300.61 253.347 1287.3 239.779 1272.17 231.046C1227.68 205.13 1182.77 216.329 1136.32 228.684C1101.68 237.902 1064.97 252.68 1022.92 269.168C1009.62 274.38 996.372 279.854 983.006 285.11C954.032 296.508 922.22 307.318 893.968 301.752C865.371 296.112 847.12 277.792 828.272 262.098C806.261 243.826 781.15 225.994 750.66 212.554C740.019 207.881 728.662 203.799 716.827 200.575C694.824 194.514 671.068 191.157 647.475 192.258C619.178 193.582 592.482 200.952 567.691 207.546C517.184 220.753 472.974 232.342 431.197 253.008C418.726 259.171 406.5 266.101 394.498 274.169'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1420.14 393.65C1420.44 357.89 1381.78 345.315 1323.9 282.806C1320.1 278.701 1316.51 274.386 1312.77 270.288C1300.11 256.401 1286.91 243.079 1272.07 234.251C1228.27 208.207 1184.42 216.856 1137.93 228.091C1103.8 236.341 1067.37 250.315 1025.35 266.327C1012.16 271.351 999.015 276.662 985.771 281.778C957.06 292.872 925.707 303.498 897.692 298.796C869.336 294.031 850.689 277.406 831.476 263.203C809.031 246.67 783.674 230.497 753.243 218.292C742.622 214.047 731.325 210.342 719.578 207.415C697.747 201.913 674.247 198.82 650.905 199.768C622.913 200.91 596.439 207.514 571.823 213.147C521.644 224.401 477.754 233.226 435.859 251.231C423.353 256.599 411.054 262.74 398.936 270.058'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1421.94 390.155C1422.23 356.78 1382.57 347.936 1323.36 285.359C1319.56 281.344 1315.98 277.118 1312.26 273.1C1299.62 259.456 1286.55 246.347 1271.98 237.459C1228.92 211.185 1186.08 217.399 1139.55 227.501C1105.94 234.797 1069.78 247.956 1027.78 263.493C1014.7 268.332 1001.67 273.477 988.541 278.453C960.094 289.241 929.196 299.684 901.422 295.85C873.309 291.963 854.266 277.029 834.682 264.317C811.805 249.523 786.202 235.011 755.83 224.038C745.229 220.224 733.991 216.891 722.331 214.261C700.673 209.314 677.428 206.493 654.338 207.288C626.654 208.245 600.401 214.089 575.958 218.762C526.107 228.065 482.534 234.123 440.525 249.463C427.983 254.036 415.615 259.389 403.381 265.954'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1423.74 386.658C1424 355.663 1383.39 350.537 1322.83 287.91C1319.03 283.984 1315.45 279.845 1311.74 275.909C1299.11 262.511 1286.18 249.613 1271.88 240.66C1229.55 214.16 1187.73 217.958 1141.15 226.901C1108.07 233.256 1072.18 245.592 1030.2 260.647C1017.23 265.3 1004.31 270.276 991.303 275.115C963.119 285.599 932.68 295.861 905.144 292.889C877.274 289.876 857.832 276.637 837.88 265.415C814.572 252.358 788.72 239.505 758.408 229.767C747.827 226.384 736.651 223.425 725.076 221.092C703.588 216.7 680.597 214.147 657.762 214.789C630.387 215.564 604.354 220.642 580.084 224.354C530.562 231.705 487.308 234.995 445.183 247.676C432.607 251.455 420.166 256.019 407.816 261.831'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1425.54 383.159C1425.78 354.548 1384.21 353.115 1322.29 290.456C1318.5 286.617 1314.92 282.57 1311.23 278.714C1298.62 265.558 1285.81 252.874 1271.78 243.861C1230.19 217.13 1189.39 218.532 1142.77 226.303C1110.22 231.729 1074.6 243.235 1032.63 257.805C1019.77 262.274 1006.96 267.084 994.074 271.779C966.153 281.957 936.169 292.036 908.874 289.93C881.247 287.791 861.406 276.244 841.086 266.515C817.346 255.198 791.247 244.005 760.997 235.502C750.436 232.546 739.32 229.964 727.833 227.931C706.517 224.095 683.781 221.809 661.2 222.301C634.134 222.895 608.319 227.207 584.224 229.958C535.03 235.358 492.096 235.882 449.853 245.897C437.242 248.881 424.728 252.657 412.262 257.716'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1427.33 379.664C1427.56 353.438 1385.05 355.678 1321.76 293.01C1317.97 289.256 1314.39 285.298 1310.71 281.526C1298.12 268.616 1285.44 256.142 1271.69 247.065C1230.83 220.105 1191.06 219.132 1144.38 225.706C1112.36 230.216 1077.01 240.88 1035.06 254.961C1022.3 259.245 1009.6 263.889 996.839 268.443C969.181 278.318 939.653 288.212 912.601 286.971C885.218 285.71 864.977 275.854 844.29 267.617C820.116 258.038 793.769 248.505 763.581 241.234C753.039 238.709 741.985 236.501 730.583 234.765C709.44 231.487 686.959 229.469 664.63 229.805C637.872 230.213 612.278 233.762 588.356 235.556C539.49 239.003 496.876 236.763 454.517 244.113C441.869 246.302 429.284 249.29 416.705 253.595'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1429.13 376.166C1429.33 352.324 1385.91 358.219 1321.22 295.56C1317.44 291.89 1313.86 288.024 1310.19 284.335C1297.62 271.67 1285.09 259.379 1271.59 250.27C1231.52 222.991 1192.72 219.754 1145.99 225.113C1114.49 228.723 1079.42 238.531 1037.48 252.124C1024.83 256.226 1012.25 260.7 999.604 265.115C972.209 274.683 943.14 284.396 916.325 284.019C889.186 283.633 868.546 275.472 847.494 268.724C822.89 260.886 796.293 253.012 766.165 246.976C755.643 244.882 744.649 243.047 733.334 241.608C712.361 238.885 690.137 237.136 668.06 237.321C641.608 237.548 616.235 240.331 592.485 241.164C543.948 242.661 501.653 237.653 459.178 242.342C446.497 243.737 433.838 245.933 421.143 249.491'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1430.93 372.671C1431.11 351.214 1386.77 360.741 1320.69 298.114C1316.91 294.529 1313.33 290.757 1309.68 287.147C1297.12 274.728 1284.72 262.648 1271.5 253.475C1232.16 225.966 1194.39 220.399 1147.6 224.52C1116.64 227.245 1081.83 236.188 1039.91 249.284C1027.37 253.203 1014.89 257.509 1002.37 261.783C975.24 271.048 946.626 280.575 920.052 281.064C893.156 281.552 872.118 275.086 850.697 269.829C825.66 263.73 798.818 257.515 768.749 252.712C758.244 251.046 747.315 249.584 736.085 248.445C715.285 246.277 693.313 244.796 671.493 244.832C645.35 244.876 620.197 246.893 596.62 246.766C548.411 246.309 506.436 238.538 463.842 240.561C451.124 241.161 438.394 242.569 425.586 245.374'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1432.73 369.173C1432.89 350.099 1387.65 363.233 1320.16 300.66C1316.38 297.159 1312.79 293.479 1309.16 289.952C1296.62 277.779 1284.36 265.909 1271.4 256.675C1232.8 228.94 1196.05 221.066 1149.21 223.919C1118.78 225.774 1084.24 233.839 1042.34 246.44C1029.9 250.177 1017.54 254.31 1005.14 258.447C978.268 267.406 950.113 276.751 923.777 278.104C897.124 279.47 875.689 274.693 853.898 270.93C828.428 266.57 801.339 262.015 771.333 258.447C760.848 257.212 749.978 256.123 738.836 255.284C718.205 253.672 696.491 252.462 674.923 252.341C649.088 252.201 624.156 253.455 600.752 252.367C552.871 249.96 511.216 239.421 468.506 238.783C455.754 238.588 442.951 239.207 430.026 241.26'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1434.53 365.675C1434.67 348.986 1388.55 365.704 1319.62 303.211C1315.85 299.789 1312.26 296.214 1308.64 292.761C1296.1 280.859 1283.99 269.174 1271.3 259.88C1233.44 231.918 1197.71 221.762 1150.82 223.326C1120.91 224.322 1086.65 231.506 1044.77 243.599C1032.44 247.158 1020.18 251.119 1007.9 255.115C981.299 263.771 953.599 272.931 927.504 275.149C901.094 277.39 879.261 274.307 857.102 272.034C831.201 269.411 803.864 266.519 773.917 264.183C763.451 263.378 752.641 262.663 741.587 262.121C721.129 261.068 699.669 260.123 678.353 259.852C652.826 259.529 628.112 260.017 604.883 257.972C557.331 253.612 515.996 240.309 473.169 237.005C460.38 236.016 447.507 235.847 434.466 237.146'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1436.32 362.18C1436.45 347.875 1389.45 368.152 1319.09 305.764C1315.32 302.425 1311.73 298.946 1308.13 295.57C1295.61 283.914 1283.62 272.443 1271.21 263.081C1234.08 234.892 1199.37 222.483 1152.44 222.726C1123.05 222.879 1089.07 229.167 1047.2 240.756C1034.98 244.136 1022.83 247.924 1010.67 251.777C984.33 260.126 957.089 269.107 931.231 272.187C905.065 275.302 882.832 273.914 860.305 273.132C833.972 272.248 806.388 271.016 776.5 269.912C766.055 269.535 755.307 269.197 744.337 268.951C724.052 268.453 702.844 267.78 681.783 267.352C656.565 266.848 632.071 266.57 609.015 263.563C561.791 257.253 520.776 241.183 477.83 235.218C465.008 233.434 452.06 232.476 438.906 233.025'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1438.12 358.681C1438.23 346.76 1390.37 370.57 1318.55 308.313C1314.79 305.055 1311.2 301.672 1307.61 298.378C1295.11 286.964 1283.26 275.707 1271.11 266.285C1234.72 237.87 1201.01 223.233 1154.05 222.132C1125.17 221.455 1091.48 226.839 1049.62 237.914C1037.51 241.119 1025.48 244.732 1013.44 248.444C987.358 256.49 960.572 265.286 934.955 269.234C909.032 273.224 886.401 273.53 863.509 274.239C836.746 275.094 808.913 275.522 779.084 275.653C768.659 275.707 757.97 275.742 747.088 275.793C726.973 275.851 706.022 275.445 685.216 274.868C660.306 274.178 636.03 273.138 613.15 269.171C566.254 260.907 525.558 242.07 482.497 233.443C469.64 230.864 456.62 229.118 443.35 228.914'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1439.92 355.187C1440 345.65 1391.31 372.965 1318.02 310.867C1314.26 307.688 1310.66 304.401 1307.09 301.19C1294.61 290.022 1282.89 278.976 1271.02 269.49C1235.36 240.845 1202.65 224.012 1155.66 221.538C1127.29 220.045 1093.9 224.516 1052.05 235.078C1040.05 238.106 1028.12 241.544 1016.2 245.115C990.389 252.855 964.062 261.47 938.682 266.283C913.003 271.15 889.972 273.145 866.712 275.347C839.516 277.942 811.434 280.029 781.668 281.392C771.262 281.877 760.635 282.286 749.836 282.634C729.893 283.246 709.197 283.109 688.643 282.378C664.039 281.507 639.987 279.7 617.279 274.776C570.711 264.562 530.335 242.958 487.158 231.666C474.264 228.292 461.173 225.758 447.787 224.8'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1441.72 351.689C1441.78 344.537 1392.25 375.327 1317.48 313.415C1313.74 310.312 1310.14 307.127 1306.58 303.996C1294.11 293.071 1282.52 282.238 1270.92 272.692C1236 243.82 1204.28 224.817 1157.27 220.939C1129.41 218.641 1096.31 222.193 1054.48 232.231C1042.59 235.084 1030.77 238.343 1018.97 241.777C993.42 249.214 967.548 257.643 942.412 263.321C916.976 269.063 893.547 272.753 869.919 276.445C842.29 280.78 813.961 284.527 784.257 287.125C773.871 288.038 763.304 288.823 752.593 289.471C732.822 290.642 712.378 290.773 692.079 289.889C667.783 288.832 643.952 286.263 621.413 280.378C575.174 268.214 535.118 243.842 491.822 229.888C478.894 225.72 465.729 222.394 452.23 220.687'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1443.52 348.191C1443.56 343.423 1393.21 377.66 1316.95 315.965C1313.21 312.939 1309.61 309.853 1306.06 306.805C1293.62 296.125 1282.15 285.506 1270.83 275.893C1236.64 246.794 1205.9 225.65 1158.88 220.342C1131.52 217.252 1098.72 219.879 1056.91 229.39C1045.13 232.071 1033.42 235.151 1021.74 238.445C996.451 245.575 971.038 253.823 946.139 260.366C920.946 266.982 897.118 272.366 873.122 277.55C845.063 283.623 816.485 289.03 786.841 292.86C776.475 294.204 765.97 295.362 755.343 296.307C735.743 298.034 715.556 298.433 695.511 297.399C671.524 296.16 647.911 292.825 625.548 285.979C579.637 271.862 539.901 244.726 496.488 228.107C483.524 223.144 470.288 219.03 456.673 216.572'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1445.31 344.696C1445.33 342.312 1394.18 379.964 1316.41 318.518C1312.68 315.569 1309.07 312.584 1305.54 309.616C1293.11 299.179 1281.78 288.774 1270.73 279.1C1237.27 249.775 1207.49 226.517 1160.49 219.748C1133.61 215.876 1101.14 217.629 1059.33 226.549C1047.66 229.074 1036.06 231.959 1024.5 235.113C999.476 241.94 974.518 250.002 949.86 257.41C924.911 264.904 900.686 271.98 876.32 278.654C847.828 286.467 819.004 293.533 789.419 298.598C779.073 300.373 768.627 301.905 758.088 303.147C738.66 305.429 718.728 306.096 698.935 304.908C675.257 303.488 651.864 299.39 629.674 291.583C584.091 275.516 544.675 245.613 501.147 226.332C488.148 220.574 474.839 215.672 461.11 212.461'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
      <path
        d='M1447.11 341.198C1447.11 341.198 1395.16 382.236 1315.88 321.068C1236.59 259.899 1209.3 193.901 1061.76 223.708C959.808 244.308 863.627 294.832 760.845 309.983C718.556 316.216 674.235 313.28 633.811 297.184C588.557 279.164 549.46 246.497 505.813 224.551C492.778 217.999 479.401 212.308 465.554 208.344'
        stroke='#0070EE'
        strokeOpacity='0.05'
        strokeMiterlimit='10'
      />
    </svg>
  );
}
