exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-anniversary-message-wall-2022-js": () => import("./../../../src/pages/anniversary/message-wall-2022.js" /* webpackChunkName: "component---src-pages-anniversary-message-wall-2022-js" */),
  "component---src-pages-anniversary-message-wall-maker-js": () => import("./../../../src/pages/anniversary/message-wall-maker.js" /* webpackChunkName: "component---src-pages-anniversary-message-wall-maker-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-hoc-2022-js": () => import("./../../../src/pages/hoc2022.js" /* webpackChunkName: "component---src-pages-hoc-2022-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-members-js": () => import("./../../../src/pages/members.js" /* webpackChunkName: "component---src-pages-members-js" */)
}

