import * as React from 'react';

export default function DotsRowSmallDecoration({ className, style }) {
  return (
    <svg
      className={className}
      style={style}
      viewBox='0 0 195 7'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.86359 6.04014C1.29084 6.04014 0 4.76415 0 3.17657C0 1.60382 1.276 0.312988 2.86359 0.312988C4.45117 0.312988 5.72718 1.58898 5.72718 3.17657C5.72718 4.76415 4.45117 6.04014 2.86359 6.04014Z'
        fill='#00214E'
      />
      <path
        d='M29.7781 6.04014C28.2054 6.04014 26.9146 4.76415 26.9146 3.17657C26.9146 1.60382 28.1906 0.312988 29.7781 0.312988C31.3657 0.312988 32.6417 1.58898 32.6417 3.17657C32.6417 4.76415 31.3509 6.04014 29.7781 6.04014Z'
        fill='#00214E'
      />
      <path
        d='M56.678 6.04014C55.1053 6.04014 53.8145 4.76415 53.8145 3.17657C53.8145 1.60382 55.0905 0.312988 56.678 0.312988C58.2508 0.312988 59.5416 1.58898 59.5416 3.17657C59.5416 4.76415 58.2656 6.04014 56.678 6.04014Z'
        fill='#00214E'
      />
      <path
        d='M83.5931 6.04014C82.0203 6.04014 80.7295 4.76415 80.7295 3.17657C80.7295 1.60382 82.0055 0.312988 83.5931 0.312988C85.1658 0.312988 86.4567 1.58898 86.4567 3.17657C86.4567 4.76415 85.1658 6.04014 83.5931 6.04014Z'
        fill='#00214E'
      />
      <path
        d='M110.508 6.04014C108.935 6.04014 107.644 4.76415 107.644 3.17657C107.644 1.60382 108.92 0.312988 110.508 0.312988C112.08 0.312988 113.371 1.58898 113.371 3.17657C113.356 4.76415 112.08 6.04014 110.508 6.04014Z'
        fill='#00214E'
      />
      <path
        d='M137.408 6.04014C135.835 6.04014 134.544 4.76415 134.544 3.17657C134.544 1.60382 135.82 0.312988 137.408 0.312988C138.98 0.312988 140.271 1.58898 140.271 3.17657C140.271 4.76415 138.98 6.04014 137.408 6.04014Z'
        fill='#00214E'
      />
      <path
        d='M164.323 6.04014C162.75 6.04014 161.459 4.76415 161.459 3.17657C161.459 1.60382 162.735 0.312988 164.323 0.312988C165.895 0.312988 167.186 1.58898 167.186 3.17657C167.171 4.76415 165.895 6.04014 164.323 6.04014Z'
        fill='#00214E'
      />
      <path
        d='M191.222 6.04014C189.65 6.04014 188.359 4.76415 188.359 3.17657C188.359 1.60382 189.635 0.312988 191.222 0.312988C192.795 0.312988 194.086 1.58898 194.086 3.17657C194.086 4.76415 192.81 6.04014 191.222 6.04014Z'
        fill='#00214E'
      />
    </svg>
  );
}
