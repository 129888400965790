import * as React from 'react';

export default function DotsDecoration({ className, style }) {
  return (
    <svg
      className={className}
      style={style}
      viewBox='0 0 290 274'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <ellipse
        cx='3.90772'
        cy='4.01762'
        rx='3.90772'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='3.90772'
        cy='28.1233'
        rx='3.90772'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='3.90772'
        cy='52.229'
        rx='3.90772'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='3.90772'
        cy='76.3348'
        rx='3.90772'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='3.90772'
        cy='100.44'
        rx='3.90772'
        ry='4.01761'
        fill='currentColor'
      />
      <ellipse
        cx='3.90772'
        cy='124.546'
        rx='3.90772'
        ry='4.01761'
        fill='currentColor'
      />
      <ellipse
        cx='3.90772'
        cy='148.652'
        rx='3.90772'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='3.90772'
        cy='172.758'
        rx='3.90772'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='3.90772'
        cy='196.863'
        rx='3.90772'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='3.90772'
        cy='220.969'
        rx='3.90772'
        ry='4.01763'
        fill='currentColor'
      />
      <ellipse
        cx='3.90772'
        cy='245.075'
        rx='3.90772'
        ry='4.01759'
        fill='currentColor'
      />
      <ellipse
        cx='3.90772'
        cy='269.18'
        rx='3.90772'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='27.354'
        cy='4.01762'
        rx='3.90772'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='27.354'
        cy='28.1233'
        rx='3.90772'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='27.354'
        cy='52.229'
        rx='3.90772'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='27.354'
        cy='76.3348'
        rx='3.90772'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='27.354'
        cy='100.44'
        rx='3.90772'
        ry='4.01761'
        fill='currentColor'
      />
      <ellipse
        cx='27.354'
        cy='124.546'
        rx='3.90772'
        ry='4.01761'
        fill='currentColor'
      />
      <ellipse
        cx='27.354'
        cy='148.652'
        rx='3.90772'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='27.354'
        cy='172.758'
        rx='3.90772'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='27.354'
        cy='196.863'
        rx='3.90772'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='27.354'
        cy='220.969'
        rx='3.90772'
        ry='4.01763'
        fill='currentColor'
      />
      <ellipse
        cx='27.354'
        cy='245.075'
        rx='3.90772'
        ry='4.01759'
        fill='currentColor'
      />
      <ellipse
        cx='27.354'
        cy='269.18'
        rx='3.90772'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='50.8003'
        cy='4.01762'
        rx='3.90773'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='50.8003'
        cy='28.1233'
        rx='3.90773'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='50.8003'
        cy='52.229'
        rx='3.90773'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='50.8003'
        cy='76.3348'
        rx='3.90773'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='50.8003'
        cy='100.44'
        rx='3.90773'
        ry='4.01761'
        fill='currentColor'
      />
      <ellipse
        cx='50.8003'
        cy='124.546'
        rx='3.90773'
        ry='4.01761'
        fill='currentColor'
      />
      <ellipse
        cx='50.8003'
        cy='148.652'
        rx='3.90773'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='50.8003'
        cy='172.758'
        rx='3.90773'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='50.8003'
        cy='196.863'
        rx='3.90773'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='50.8003'
        cy='220.969'
        rx='3.90773'
        ry='4.01763'
        fill='currentColor'
      />
      <ellipse
        cx='50.8003'
        cy='245.075'
        rx='3.90773'
        ry='4.01759'
        fill='currentColor'
      />
      <ellipse
        cx='50.8003'
        cy='269.18'
        rx='3.90773'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='74.2468'
        cy='4.01762'
        rx='3.90772'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='74.2468'
        cy='28.1233'
        rx='3.90772'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='74.2468'
        cy='52.229'
        rx='3.90772'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='74.2468'
        cy='76.3348'
        rx='3.90772'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='74.2468'
        cy='100.44'
        rx='3.90772'
        ry='4.01761'
        fill='currentColor'
      />
      <ellipse
        cx='74.2468'
        cy='124.546'
        rx='3.90772'
        ry='4.01761'
        fill='currentColor'
      />
      <ellipse
        cx='74.2468'
        cy='148.652'
        rx='3.90772'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='74.2468'
        cy='172.758'
        rx='3.90772'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='74.2468'
        cy='196.863'
        rx='3.90772'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='74.2468'
        cy='220.969'
        rx='3.90772'
        ry='4.01763'
        fill='currentColor'
      />
      <ellipse
        cx='74.2468'
        cy='245.075'
        rx='3.90772'
        ry='4.01759'
        fill='currentColor'
      />
      <ellipse
        cx='74.2468'
        cy='269.18'
        rx='3.90772'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='97.6931'
        cy='4.01762'
        rx='3.90772'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='97.6931'
        cy='28.1233'
        rx='3.90772'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='97.6931'
        cy='52.229'
        rx='3.90772'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='97.6931'
        cy='76.3348'
        rx='3.90772'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='97.6931'
        cy='100.44'
        rx='3.90772'
        ry='4.01761'
        fill='currentColor'
      />
      <ellipse
        cx='97.6931'
        cy='124.546'
        rx='3.90772'
        ry='4.01761'
        fill='currentColor'
      />
      <ellipse
        cx='97.6931'
        cy='148.652'
        rx='3.90772'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='97.6931'
        cy='172.758'
        rx='3.90772'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='97.6931'
        cy='196.863'
        rx='3.90772'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='97.6931'
        cy='220.969'
        rx='3.90772'
        ry='4.01763'
        fill='currentColor'
      />
      <ellipse
        cx='97.6931'
        cy='245.075'
        rx='3.90772'
        ry='4.01759'
        fill='currentColor'
      />
      <ellipse
        cx='97.6931'
        cy='269.18'
        rx='3.90772'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='121.139'
        cy='4.01762'
        rx='3.90773'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='121.139'
        cy='28.1233'
        rx='3.90773'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='121.139'
        cy='52.229'
        rx='3.90773'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='121.139'
        cy='76.3348'
        rx='3.90773'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='121.139'
        cy='100.44'
        rx='3.90773'
        ry='4.01761'
        fill='currentColor'
      />
      <ellipse
        cx='121.139'
        cy='124.546'
        rx='3.90773'
        ry='4.01761'
        fill='currentColor'
      />
      <ellipse
        cx='121.139'
        cy='148.652'
        rx='3.90773'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='121.139'
        cy='172.758'
        rx='3.90773'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='121.139'
        cy='196.863'
        rx='3.90773'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='121.139'
        cy='220.969'
        rx='3.90773'
        ry='4.01763'
        fill='currentColor'
      />
      <ellipse
        cx='121.139'
        cy='245.075'
        rx='3.90773'
        ry='4.01759'
        fill='currentColor'
      />
      <ellipse
        cx='121.139'
        cy='269.18'
        rx='3.90773'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='144.586'
        cy='4.01762'
        rx='3.90772'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='144.586'
        cy='28.1233'
        rx='3.90772'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='144.586'
        cy='52.229'
        rx='3.90772'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='144.586'
        cy='76.3348'
        rx='3.90772'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='144.586'
        cy='100.44'
        rx='3.90772'
        ry='4.01761'
        fill='currentColor'
      />
      <ellipse
        cx='144.586'
        cy='124.546'
        rx='3.90772'
        ry='4.01761'
        fill='currentColor'
      />
      <ellipse
        cx='144.586'
        cy='148.652'
        rx='3.90772'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='144.586'
        cy='172.758'
        rx='3.90772'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='144.586'
        cy='196.863'
        rx='3.90772'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='144.586'
        cy='220.969'
        rx='3.90772'
        ry='4.01763'
        fill='currentColor'
      />
      <ellipse
        cx='144.586'
        cy='245.075'
        rx='3.90772'
        ry='4.01759'
        fill='currentColor'
      />
      <ellipse
        cx='144.586'
        cy='269.18'
        rx='3.90772'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='168.032'
        cy='4.01762'
        rx='3.90772'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='191.479'
        cy='4.01762'
        rx='3.90773'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='214.925'
        cy='4.01762'
        rx='3.90773'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='238.371'
        cy='4.01762'
        rx='3.90773'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='261.817'
        cy='4.01762'
        rx='3.90771'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='285.264'
        cy='4.01762'
        rx='3.90771'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='168.032'
        cy='28.1233'
        rx='3.90772'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='191.479'
        cy='28.1233'
        rx='3.90773'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='214.925'
        cy='28.1233'
        rx='3.90773'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='238.371'
        cy='28.1233'
        rx='3.90773'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='261.817'
        cy='28.1233'
        rx='3.90771'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='285.264'
        cy='28.1233'
        rx='3.90771'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='168.032'
        cy='52.229'
        rx='3.90772'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='191.479'
        cy='52.229'
        rx='3.90773'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='214.925'
        cy='52.229'
        rx='3.90773'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='238.371'
        cy='52.229'
        rx='3.90773'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='261.817'
        cy='52.229'
        rx='3.90771'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='285.264'
        cy='52.229'
        rx='3.90771'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='168.032'
        cy='76.3348'
        rx='3.90772'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='191.479'
        cy='76.3348'
        rx='3.90773'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='214.925'
        cy='76.3348'
        rx='3.90773'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='238.371'
        cy='76.3348'
        rx='3.90773'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='261.817'
        cy='76.3348'
        rx='3.90771'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='285.264'
        cy='76.3348'
        rx='3.90771'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='168.032'
        cy='100.44'
        rx='3.90772'
        ry='4.01761'
        fill='currentColor'
      />
      <ellipse
        cx='191.479'
        cy='100.44'
        rx='3.90773'
        ry='4.01761'
        fill='currentColor'
      />
      <ellipse
        cx='214.925'
        cy='100.44'
        rx='3.90773'
        ry='4.01761'
        fill='currentColor'
      />
      <ellipse
        cx='238.371'
        cy='100.44'
        rx='3.90773'
        ry='4.01761'
        fill='currentColor'
      />
      <ellipse
        cx='261.817'
        cy='100.44'
        rx='3.90771'
        ry='4.01761'
        fill='currentColor'
      />
      <ellipse
        cx='285.264'
        cy='100.44'
        rx='3.90771'
        ry='4.01761'
        fill='currentColor'
      />
      <ellipse
        cx='168.032'
        cy='124.546'
        rx='3.90772'
        ry='4.01761'
        fill='currentColor'
      />
      <ellipse
        cx='191.479'
        cy='124.546'
        rx='3.90773'
        ry='4.01761'
        fill='currentColor'
      />
      <ellipse
        cx='214.925'
        cy='124.546'
        rx='3.90773'
        ry='4.01761'
        fill='currentColor'
      />
      <ellipse
        cx='238.371'
        cy='124.546'
        rx='3.90773'
        ry='4.01761'
        fill='currentColor'
      />
      <ellipse
        cx='261.817'
        cy='124.546'
        rx='3.90771'
        ry='4.01761'
        fill='currentColor'
      />
      <ellipse
        cx='285.264'
        cy='124.546'
        rx='3.90771'
        ry='4.01761'
        fill='currentColor'
      />
      <ellipse
        cx='168.032'
        cy='148.652'
        rx='3.90772'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='191.479'
        cy='148.652'
        rx='3.90773'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='214.925'
        cy='148.652'
        rx='3.90773'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='238.371'
        cy='148.652'
        rx='3.90773'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='261.817'
        cy='148.652'
        rx='3.90771'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='285.264'
        cy='148.652'
        rx='3.90771'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='168.032'
        cy='172.758'
        rx='3.90772'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='191.479'
        cy='172.758'
        rx='3.90773'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='214.925'
        cy='172.758'
        rx='3.90773'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='238.371'
        cy='172.758'
        rx='3.90773'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='261.817'
        cy='172.758'
        rx='3.90771'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='285.264'
        cy='172.758'
        rx='3.90771'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='168.032'
        cy='196.863'
        rx='3.90772'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='191.479'
        cy='196.863'
        rx='3.90773'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='214.925'
        cy='196.863'
        rx='3.90773'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='238.371'
        cy='196.863'
        rx='3.90773'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='261.817'
        cy='196.863'
        rx='3.90771'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='285.264'
        cy='196.863'
        rx='3.90771'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='168.032'
        cy='220.969'
        rx='3.90772'
        ry='4.01763'
        fill='currentColor'
      />
      <ellipse
        cx='191.479'
        cy='220.969'
        rx='3.90773'
        ry='4.01763'
        fill='currentColor'
      />
      <ellipse
        cx='214.925'
        cy='220.969'
        rx='3.90773'
        ry='4.01763'
        fill='currentColor'
      />
      <ellipse
        cx='238.371'
        cy='220.969'
        rx='3.90773'
        ry='4.01763'
        fill='currentColor'
      />
      <ellipse
        cx='261.817'
        cy='220.969'
        rx='3.90771'
        ry='4.01763'
        fill='currentColor'
      />
      <ellipse
        cx='285.264'
        cy='220.969'
        rx='3.90771'
        ry='4.01763'
        fill='currentColor'
      />
      <ellipse
        cx='168.032'
        cy='245.075'
        rx='3.90772'
        ry='4.01759'
        fill='currentColor'
      />
      <ellipse
        cx='191.479'
        cy='245.075'
        rx='3.90773'
        ry='4.01759'
        fill='currentColor'
      />
      <ellipse
        cx='214.925'
        cy='245.075'
        rx='3.90773'
        ry='4.01759'
        fill='currentColor'
      />
      <ellipse
        cx='238.371'
        cy='245.075'
        rx='3.90773'
        ry='4.01759'
        fill='currentColor'
      />
      <ellipse
        cx='261.817'
        cy='245.075'
        rx='3.90771'
        ry='4.01759'
        fill='currentColor'
      />
      <ellipse
        cx='285.264'
        cy='245.075'
        rx='3.90771'
        ry='4.01759'
        fill='currentColor'
      />
      <ellipse
        cx='168.032'
        cy='269.18'
        rx='3.90772'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='191.479'
        cy='269.18'
        rx='3.90773'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='214.925'
        cy='269.18'
        rx='3.90773'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='238.371'
        cy='269.18'
        rx='3.90773'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='261.817'
        cy='269.18'
        rx='3.90771'
        ry='4.01762'
        fill='currentColor'
      />
      <ellipse
        cx='285.264'
        cy='269.18'
        rx='3.90771'
        ry='4.01762'
        fill='currentColor'
      />
    </svg>
  );
}
